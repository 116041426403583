import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "reporting-statements-and-receipts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#reporting-statements-and-receipts",
        "aria-label": "reporting statements and receipts permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Reporting, statements and receipts`}</h1>
    <p>{`Medipass automatically generates patient reports, statements and receipts on your behalf.  This includes generation of Medicare assignment of benefits forms, lodgement advice and other statements in formats that adhere to funder guidelines. These reports and receipts can be obtained from Medipass provider portal or via API calls. We also make patient/claimant reports, statements and receipts available online in my.medipass.io for patients/claimants with a Medipass member account.`}</p>
    <h3 {...{
      "id": "comcare",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#comcare",
        "aria-label": "comcare permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Comcare`}</h3>
    <p>{`Invoices are automatically generated by Medipass for each injured worker and the treatments delivered. These invoices are directly sent to Comcare for approval. Providers can print copies of the invoice from the Medipass provider portal.`}</p>
    <h3 {...{
      "id": "hicaps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hicaps",
        "aria-label": "hicaps permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`HICAPS`}</h3>
    <p>{`HICAPS receipts are automatically generated by Medipass. For HICAPS go transactions, receipts and claim statements are available online in my.medipass.io or patients/claimants can send those receipts to a nominated email address.`}</p>
    <h3 {...{
      "id": "icare",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#icare",
        "aria-label": "icare permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`icare`}</h3>
    <p>{`Invoices are automatically generated by Medipass for each injured worker and the treatments delivered. These invoices are directly sent to icare for approval. Providers can print copies of the invoice from the Medipass provider portal.`}</p>
    <h3 {...{
      "id": "medicare",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#medicare",
        "aria-label": "medicare permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Medicare`}</h3>
    <h5 {...{
      "id": "patientclaimant-statements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#patientclaimant-statements",
        "aria-label": "patientclaimant statements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patient/claimant statements`}</h5>
    <p>{`For each Medicare transaction there is a `}<strong parentName="p">{`Medicare required patient or claimant statement`}</strong>{`. This statement is available on Patient Claim Interactive (PCI) and Bulk Bill transactions at the time the claim is submitted to Medicare or stored for later transmission. It’s important that patients and claimants be given Medicare statements as there are penalties for providers who omit those statements. By default, Medicare receipts and statements are available online in my.medipass.io or patients/claimants can send those receipts to a nominated email address.`}</p>
    <p>{`You can also obtain those statements directly within the Transaction SDK using the `}<a parentName="p" {...{
        "href": "/sdk/sdk-reference/transactions/#sdktransactionsdownloadpdf"
      }}>{`'Download PDF' method`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.transactions.downloadPDF({ transactionId: '123' });
`}</code></pre>
    <h5 {...{
      "id": "processing--payment-reports",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h5" {...{
        "href": "#processing--payment-reports",
        "aria-label": "processing  payment reports permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Processing & payment reports`}</h5>
    <p>{`For Bulk Bill transactions, there is also a `}<strong parentName="p">{`Processing Report`}</strong>{` and a `}<strong parentName="p">{`Payment Report`}</strong>{`. These two reports are only available after a bulk-bill claim has been processed by Medicare (typically next business day).`}</p>
    <p>{`A processing & payment report is typically available when the `}<a parentName="p" {...{
        "href": "/sdk/getting-started/submit/#set-a-transaction-webhook"
      }}><inlineCode parentName="a">{`healthFundApprovedInvoice`}</inlineCode>{` webhook`}</a>{` event has been invoked.`}</p>
    <p>{`You can fetch the processing & payment report by using the `}<a parentName="p" {...{
        "href": "/sdk/sdk-reference/transactions/#sdktransactionsgetpaymentreport"
      }}>{`'Get payment report' method`}</a>{` and `}<a parentName="p" {...{
        "href": "/sdk/sdk-reference/transactions/#sdktransactionsgetprocessingreport"
      }}>{`'Get processing report' method`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`medipassTransactionSDK.transactions.getPaymentReport({ transactionId: '123'});

medipassTransactionSDK.transactions.getProcessingReport({ transactionId: '123'});
`}</code></pre>
    <h3 {...{
      "id": "patient-funded",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#patient-funded",
        "aria-label": "patient funded permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Patient funded`}</h3>
    <p>{`Patient funded receipts are automatically generated by Medipass. For online transactions, receipts and claim statements are available online in my.medipass.io or patients/claimants can send those receipts to a nominated email address.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      